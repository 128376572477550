@tailwind base;
@tailwind components;
@tailwind utilities;

body {
  @apply bg-gray-100 font-sans leading-relaxed text-gray-800;
}

h1 {
  @apply text-blue-600 text-center text-4xl mb-8 font-bold shadow-sm;
}

h2 {
  @apply text-left;
}

div {
  @apply text-left;
}

h2, .itinerary-day {
  @apply mb-4;
}
